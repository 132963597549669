import React from "react"
import styled from "styled-components"

import type Post from "Types/Post"
import Category from "Styles/category"
import DateTime from "Styles/dateTime"
import CenteredImg from "./centeredImg"

type CardProps = Pick<
  Post,
  "thumbnail" | "alt" | "category" | "title" | "desc" | "date"
>

const Card: React.FC<CardProps> = ({
  thumbnail,
  alt,
  category,
  title,
  desc,
  date,
}) => {
  return (
    <Wrapper>
      <CenteredImg src={thumbnail} alt={alt} />
      <Text>
        <div>
        <Category>{category}</Category> 
          <Title>{title}</Title>
          <Desc>{desc}</Desc>
        </div>
        <DateTime dateTime={date}>{date}</DateTime>
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  /*border-radius: var(--border-radius-base);*/
  background-color: var(--color-card);
  padding-top: var(--sizing-l);
  /* Fix Safari overflow:hidden with border radius not working error */
  transform: translateZ(0);
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;
  margin-top: var(--sizing-md);

  & > * {
    display: block;
  }
`
const Title = styled.h3`
  font-size: var(--text-lg);
  font-family: var(--font-display);
  line-height: var(--text-base);
  @media (max-width: ${({ theme }) => theme.device.md}) {
    font-size: var(--text-lg);
    line-height: var(--text-base);
  }

  @media (max-width: ${({ theme }) => theme.device.sm}) {
    font-size: var(--text-md);
    line-height: var(--text-base);
  }
`

const Desc = styled.p`
  padding-top: var(--sizing-base);
  padding-bottom: var(--sizing-base);
  font-size: var(--text-sm);
  line-height: var(--text-base);
  color: var(--color-text-2);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default React.memo(Card)
