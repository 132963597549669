import styled from "styled-components"

const Category = styled.span`
  display: block;
  font-size: var(--text-xs);
  font-weight: var(--font-weight-normal);
  color: var(--color-gray-4);
`

export default Category
